import { createSlice } from "@reduxjs/toolkit";
import {
  minerals_fetchAllData,
  minerals_fetchDataByID,
  minerals_updateData,
  minerals_uploadImg,
} from "./mineralActions";
const initialState = {
  minerals_data: null,
  minerals_loading: false,
  minerals_error: false,
  minerals_errCode: null,

  mineral_upload_data: null,
  mineral_upload_loading: false,
  mineral_upload_error: false,
  mineral_upload_errCode: null,
};

const mineralSlice = createSlice({
  name: "minerals",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(minerals_fetchAllData.pending, (state) => {
        state.minerals_loading = true;
        state.minerals_error = false;
      })
      .addCase(minerals_fetchAllData.fulfilled, (state, action) => {
        state.minerals_loading = false;
        state.minerals_data = action.payload;
      })
      .addCase(minerals_fetchAllData.rejected, (state, action) => {
        state.minerals_loading = false;
        state.minerals_error = true;
        state.minerals_errCode = action.error.message;
      })
      .addCase(minerals_fetchDataByID.pending, (state) => {
        state.minerals_loading = true;
        state.minerals_error = null;
      })
      .addCase(minerals_fetchDataByID.fulfilled, (state, action) => {
        state.minerals_loading = false;
        state.minerals_data = action.payload;
      })
      .addCase(minerals_fetchDataByID.rejected, (state, action) => {
        state.minerals_loading = false;
        state.minerals_error = action.error.message;
      })
      .addCase(minerals_updateData.pending, (state) => {
        state.minerals_loading = true;
        state.minerals_error = null;
      })
      .addCase(minerals_updateData.fulfilled, (state, action) => {
        state.minerals_loading = false;
        state.minerals_error = false;
        state.minerals_data = action.payload;
      })
      .addCase(minerals_updateData.rejected, (state, action) => {
        state.minerals_loading = false;
        state.minerals_error = action.error.message;
      })
      .addCase(minerals_uploadImg.pending, (state) => {
        state.mineral_upload_loading = true;
        state.mineral_upload_error = null;
      })
      .addCase(minerals_uploadImg.fulfilled, (state, action) => {
        state.mineral_upload_loading = false;
        state.mineral_upload_error = false;
        state.mineral_upload_data = action.payload;
      })
      .addCase(minerals_uploadImg.rejected, (state, action) => {
        state.mineral_upload_loading = false;
        state.mineral_upload_error = action.error.message;
      });
  },
});

export default mineralSlice.reducer;
