import { createAsyncThunk } from "@reduxjs/toolkit";
import privateAPIServices from "../../../services/privateAPIServices";

const API = "/vitamins/";

// Action to fetch all data
export const vitamins_fetchAllData = createAsyncThunk(
  "vitamins/fetch-all-data",
  async () => {
    try {
      const response = await privateAPIServices.fetchData(API + "get-all-data");
      return response;
    } catch (error) {
      throw new Error(error.status);
    }
  }
);

export const vitamins_fetchDataByID = createAsyncThunk(
  "vitamins/fetch-by-id",
  async (ID) => {
    try {
      const response = await privateAPIServices.fetchData(
        API + "get-data-by-id/" + ID
      );
      return response;
    } catch (error) {
      throw new Error(error.status);
    }
  }
);

export const vitamins_uploadImg = createAsyncThunk(
  "vitamins/upload-image",
  async (payload) => {
    const { id, formData } = payload;
    try {
      const response = await privateAPIServices.postData(
        API + `upload-image?data_id=${id}`,
        formData
      );
      return response;
    } catch (error) {
      throw new Error(error.status);
    }
  }
);

export const vitamins_updateData = createAsyncThunk(
  "vitamins/update-data",
  async (payload) => {
    const { id, data } = payload;
    try {
      const response = await privateAPIServices.updateData(
        API + "update-data/" + id,
        data
      );
      return response;
    } catch (error) {
      throw new Error(error.status);
    }
  }
);
