import { createSlice } from "@reduxjs/toolkit";
import {
  ingredient_categories_fetchAllData,
  ingredient_categories_fetchDataByID,
} from "./ingredientCategoryActions";
const initialState = {
  ingredient_categories_data: null,
  ingredient_categories_loading: false,
  ingredient_categories_error: false,
  ingredient_categories_errCode: null,

  mineral_upload_data: null,
  mineral_upload_loading: false,
  mineral_upload_error: false,
  mineral_upload_errCode: null,
};

const mineralSlice = createSlice({
  name: "ingredient_categories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ingredient_categories_fetchAllData.pending, (state) => {
        state.ingredient_categories_loading = true;
        state.ingredient_categories_error = false;
      })
      .addCase(
        ingredient_categories_fetchAllData.fulfilled,
        (state, action) => {
          state.ingredient_categories_loading = false;
          state.ingredient_categories_data = action.payload;
        }
      )
      .addCase(ingredient_categories_fetchAllData.rejected, (state, action) => {
        state.ingredient_categories_loading = false;
        state.ingredient_categories_error = true;
        state.ingredient_categories_errCode = action.error.message;
      })
      .addCase(ingredient_categories_fetchDataByID.pending, (state) => {
        state.ingredient_categories_loading = true;
        state.ingredient_categories_error = null;
      })
      .addCase(
        ingredient_categories_fetchDataByID.fulfilled,
        (state, action) => {
          state.ingredient_categories_loading = false;
          state.ingredient_categories_data = action.payload;
        }
      )
      .addCase(
        ingredient_categories_fetchDataByID.rejected,
        (state, action) => {
          state.ingredient_categories_loading = false;
          state.ingredient_categories_error = action.error.message;
        }
      );
  },
});

export default mineralSlice.reducer;
