import axios from "axios";
import config from "../../config/config";

import Cookies from "js-cookie"; // Import the js-cookie library

import jwt_decode from "jwt-decode";

const PUBLIC_API_URL = config.API_URL + "/api/v1/public/auth/";
const PRIVATE_API_URL = config.API_URL + "/api/v1/private/auth/";

// Register user
// const register = async (userData) => {
//   const response = await axios.post(PRIVATE_API_URL+"register", userData);

//   if (response.data) {
//     localStorage.setItem("user", JSON.stringify(response.data));
//   }

//   return response.data;
// };

// Login user
const login = async (userData) => {
  const response = await axios.post(PUBLIC_API_URL + "login", userData, {
    withCredentials: true,
  });

  const decoded = jwt_decode(response.data.session_data);
  const expire = 10000;
  Cookies.set("authenticated", 1, { expires: expire });
  Cookies.set("session_time", expire, { expires: expire });
  Cookies.set("session_data", response.data.session_data, {
    expires: expire,
  });
  return response.data;
};

//refresh token
const refreshToken = async () => {
  try {
    const response = await axios.post(
      PRIVATE_API_URL + "refresh-token",
      {},
      {
        withCredentials: true,
      }
    );

    const decoded = jwt_decode(response.data.session_data);
    const expire = new Date(Date.now() + decoded.expires);
    Cookies.set("authenticated", 1, { expires: expire });
    Cookies.set("session_time", expire, { expires: expire });
    Cookies.set("session_data", response.data.session_data, {
      expires: expire,
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }
};
//teszt
// const login = async (userData) => {
//   await fetch(PUBLIC_API_URL + "login", {
//     method: "POST", // Specify the HTTP method
//     headers: {
//       "Content-Type": "application/json", // Set the content type if sending JSON data
//       // You can set additional headers as needed
//     },
//     body: JSON.stringify(userData),
//   })
//     .then((response) => {
//       if (!response.ok) {
//         throw new Error("Network response was not ok");
//       }
//       console.log(response);
//       return response.json(); // Parse the response body as JSON
//     })
//     .then((data) => {
//       // Handle the response data
//       console.log(data);
//     })
//     .catch((error) => {
//       // Handle errors here
//       console.error("There was a problem with the fetch operation:", error);
//     });
// };
// Logout user
const logout = async () => {
  try {
    await axios.post(PRIVATE_API_URL + "logout", {}, { withCredentials: true });

    //   Cookies.remove("access_token");
    //   return response.data;
  } catch (error) {
    console.log(error);
  }
};

const authServices = {
  // register,
  logout,
  login,
  refreshToken,
};

export default authServices;
