import { createAsyncThunk } from "@reduxjs/toolkit";
import privateAPIServices from "../../../services/privateAPIServices";

const API = "/ingredient-categories/";

// Action to fetch all data
export const ingredient_categories_fetchAllData = createAsyncThunk(
  "ingredient_categories/fetch-all-data",
  async () => {
    try {
      const response = await privateAPIServices.fetchData(API + "get-all-data");
      return response;
    } catch (error) {
      throw new Error(error.status);
    }
  }
);

export const ingredient_categories_fetchDataByID = createAsyncThunk(
  "ingredient_categories/fetch-by-id",
  async (ID) => {
    try {
      const response = await privateAPIServices.fetchData(
        API + "get-data-by-id/" + ID
      );
      return response;
    } catch (error) {
      throw new Error(error.status);
    }
  }
);

// export const minerals_uploadImg = createAsyncThunk(
//   "minerals/upload-image",
//   async (payload) => {
//     const { id, formData } = payload;
//     try {
//       const response = await privateAPIServices.postData(
//         API + `upload-image?data_id=${id}`,
//         formData
//       );
//       return response;
//     } catch (error) {
//       throw new Error(error.status);
//     }
//   }
// );

// export const minerals_updateData = createAsyncThunk(
//   "minerals/update-data",
//   async (payload) => {
//     const { id, data } = payload;
//     try {
//       const response = await privateAPIServices.updateData(
//         API + "update-data/" + id,
//         data
//       );
//       return response;
//     } catch (error) {
//       throw new Error(error.status);
//     }
//   }
// );
