import axios from "axios";
import config from "../config/config";

const API_URL = config.API_URL + "/api/v1/private";

const checkAuthentication = async () => {
  try {
    const response = await axios.get(`${API_URL}/auth/check-auth`, {
      // headers: {
      //   Authorization: accessToken,
      // },
      withCredentials: true,
    });
    return true;
  } catch (error) {
    return false;
  }
};

const logoutHandler = async () => {
  await axios.post(`${API_URL}/auth/logout`, {}, { withCredentials: true });
};

const fetchData = async (url, accessToken) => {
  try {
    const response = await axios.get(
      API_URL + url,
      // {},
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// Send data to server
const postData = async (url, payload) => {
  try {
    const response = await axios.post(API_URL + url, payload, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

// Send data to server
const updateData = async (url, payload, accessToken) => {
  try {
    const response = await axios.put(API_URL + url, payload, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

//delete data from db via server
const deleteData = async (url) => {
  try {
    const response = await axios.delete(API_URL + url, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

// Fetch data by slug from server

const privateAPIServices = {
  checkAuthentication,
  postData,
  deleteData,
  fetchData,
  updateData,
  // fetchDataBySlug,
  // fetchProductByCategory,
  // fetchProductByID
  logoutHandler,
};

export default privateAPIServices;
