import { createSlice } from "@reduxjs/toolkit";
import {
  ingredient_subcategories_fetchAllData_by_category,
  ingredient_subcategories_fetchDataByID,
} from "./ingredientSubcategoryActions";
const initialState = {
  ingredient_subcategories_data: null,
  ingredient_subcategories_loading: false,
  ingredient_subcategories_error: false,
  ingredient_subcategories_errCode: null,

  mineral_upload_data: null,
  mineral_upload_loading: false,
  mineral_upload_error: false,
  mineral_upload_errCode: null,
};

const mineralSlice = createSlice({
  name: "ingredient_subcategories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        ingredient_subcategories_fetchAllData_by_category.pending,
        (state) => {
          state.ingredient_subcategories_loading = true;
          state.ingredient_subcategories_error = false;
        }
      )
      .addCase(
        ingredient_subcategories_fetchAllData_by_category.fulfilled,
        (state, action) => {
          state.ingredient_subcategories_loading = false;
          state.ingredient_subcategories_data = action.payload;
        }
      )
      .addCase(
        ingredient_subcategories_fetchAllData_by_category.rejected,
        (state, action) => {
          state.ingredient_subcategories_loading = false;
          state.ingredient_subcategories_error = true;
          state.ingredient_subcategories_errCode = action.error.message;
        }
      )
      .addCase(ingredient_subcategories_fetchDataByID.pending, (state) => {
        state.ingredient_subcategories_loading = true;
        state.ingredient_subcategories_error = null;
      })
      .addCase(
        ingredient_subcategories_fetchDataByID.fulfilled,
        (state, action) => {
          state.ingredient_subcategories_loading = false;
          state.ingredient_subcategories_data = action.payload;
        }
      )
      .addCase(
        ingredient_subcategories_fetchDataByID.rejected,
        (state, action) => {
          state.ingredient_subcategories_loading = false;
          state.ingredient_subcategories_error = action.error.message;
        }
      );
  },
});

export default mineralSlice.reducer;
