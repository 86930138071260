import { lazy, Suspense, useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Cookies from "js-cookie"; // Import the js-cookie library

import privateAPIServices from "./services/privateAPIServices";
import { logout, refreshToken } from "./context/auth/authSlice";
import jwt_decode from "jwt-decode";
import { ToastContainer } from "react-toastify";

const Header = lazy(() => import("./layout/Header/Header"));
const Sidebar = lazy(() => import("./layout/Sidebar/Sidebar"));
// const Footer = lazy(() => import("./layout/Footer/Footer"));

const Dashboard = lazy(() => import("./pages/Dashboard/Dasboard"));
const Login = lazy(() => import("./pages/User/Login/Login"));

const AllRecipes = lazy(() => import("./pages/Recipes/AllRecipes/AllRecipes"));
const CreateRecipe = lazy(() =>
  import("./pages/Recipes/CreateRecipe/CreateRecipe")
);
const EditRecipe = lazy(() => import("./pages/Recipes/EditRecipe/EditRecipe"));
//Vitamins
const AllVitamins = lazy(() =>
  import("./pages/Vitamins/AllVitamins/AllVitamins")
);
const EditVitamin = lazy(() =>
  import("./pages/Vitamins/EditVitamin/EditVitamin")
);

//Minerals
const AllMinerals = lazy(() =>
  import("./pages/Minerals/AllMinerals/AllMinerals")
);
const EditMineral = lazy(() =>
  import("./pages/Minerals/EditMineral/EditMineral")
);

//Ingredients
const ListIngredientCategories = lazy(() =>
  import(
    "./pages/Ingredients/ListIngredientCategories/ListIngredientCategories"
  )
);
const ListIngredientSubcategories = lazy(() =>
  import(
    "./pages/Ingredients/ListIngredientSubcategories/ListIngredientSubcategories"
  )
);
const AllIngredients = lazy(() =>
  import("./pages/Ingredients/AllIngredients/AllIngredients")
);
const CreateIngredient = lazy(() =>
  import("./pages/Ingredients/CreateIngredient/CreateIngredient")
);
const EditIngredient = lazy(() =>
  import("./pages/Ingredients/EditIngredient/EditIngredient")
);

const NewBlogPost = lazy(() => import("./pages/Blog/NewBlogPost/NewBlogPost"));

const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(true);

  const { user, isLoading } = useSelector((state) => state.auth);
  const [authenticated, setAuthenticated] = useState(false);

  const authStatus = Cookies.get("authenticated");
  if (user && user.session_data) {
    const test = jwt_decode(user.session_data);
  }
  useEffect(() => {
    fetchCheck();
  }, [user, pathname]);

  useEffect(() => {
    if (authStatus === "1") {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
      navigate("/login");
    }
  }, [authStatus]);

  const fetchCheck = async () => {
    setLoading(true);
    try {
      const response = await privateAPIServices.fetchData(
        "/auth/secure"
        // "/auth/check-auth-admin"
      );
      if (!user) {
        setAuthenticated(false);
        navigate("/login");
      } else if (response.message) {
        // window.location.replace("https://refitterapia.agrandgrp.com");
      } else if (
        user &&
        response &&
        response.response &&
        response.response.status &&
        response.response.status === 401
      ) {
        dispatch(refreshToken());
      } else if (user && !response) {
        // window.location.replace("https://refitterapia.agrandgrp.com");
      } else if (
        user &&
        response &&
        response.response &&
        response.response.status &&
        response.response.status === 403
      ) {
        // window.location.replace("https://refitterapia.agrandgrp.com");
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <Suspense fallback={<p>loading</p>}>
      {loading ? (
        <p>Loading</p>
      ) : (
        <>
          {authenticated && !isLoading && <Header />}

          <div className="app__container">
            {authenticated && !isLoading && (
              <div className="app__sidebar_container">
                <Sidebar />
              </div>
            )}

            <div
              className={
                authenticated && !isLoading
                  ? "app__content"
                  : "app__login_screen"
              }
            >
              <ToastContainer />

              <Routes>
                <Route path="/blog/new-post" element={<NewBlogPost />} />
                <Route path="/recipes/:id" element={<EditRecipe />} />
                <Route path="/recipes/new-recipe" element={<CreateRecipe />} />
                <Route path="/recipes" element={<AllRecipes />} />
                <Route
                  path="/ingredients/:id/:id/:id"
                  element={<EditIngredient />}
                />
                <Route
                  path="/ingredients/:id/:id/new-ingredient"
                  element={<CreateIngredient />}
                />
                <Route
                  path="/ingredients/:id/:id"
                  element={<AllIngredients />}
                />
                <Route
                  path="/ingredients/:id"
                  element={<ListIngredientSubcategories />}
                />
                <Route
                  path="/ingredients"
                  element={<ListIngredientCategories />}
                />
                <Route path="/minerals/:id" element={<EditMineral />} />
                <Route path="/minerals" element={<AllMinerals />} />
                <Route path="/vitamins/:id" element={<EditVitamin />} />
                <Route path="/vitamins" element={<AllVitamins />} />
                <Route path="/login" element={<Login />} />
                <Route exact path="/" element={<Dashboard />} />
              </Routes>
            </div>
          </div>
        </>
      )}
    </Suspense>
  );
};
export default App;
