import { createSlice } from "@reduxjs/toolkit";
import {
  vitamins_fetchAllData,
  vitamins_fetchDataByID,
  vitamins_updateData,
  vitamins_uploadImg,
} from "./vitaminsActions";
const initialState = {
  vitamins_data: null,
  vitamins_loading: false,
  vitamins_error: false,
  vitamins_errCode: null,

  vitamin_upload_data: null,
  vitamin_upload_loading: false,
  vitamin_upload_error: false,
  vitamin_upload_errCode: null,
};

const vitaminSlice = createSlice({
  name: "vitamins",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(vitamins_fetchAllData.pending, (state) => {
        state.vitamins_loading = true;
        state.vitamins_error = false;
      })
      .addCase(vitamins_fetchAllData.fulfilled, (state, action) => {
        state.vitamins_loading = false;
        state.vitamins_data = action.payload;
      })
      .addCase(vitamins_fetchAllData.rejected, (state, action) => {
        state.vitamins_loading = false;
        state.vitamins_error = true;
        state.vitamins_errCode = action.error.message;
      })
      .addCase(vitamins_fetchDataByID.pending, (state) => {
        state.vitamins_loading = true;
        state.vitamins_error = null;
      })
      .addCase(vitamins_fetchDataByID.fulfilled, (state, action) => {
        state.vitamins_loading = false;
        state.vitamins_data = action.payload;
      })
      .addCase(vitamins_fetchDataByID.rejected, (state, action) => {
        state.vitamins_loading = false;
        state.vitamins_error = action.error.message;
      })
      .addCase(vitamins_updateData.pending, (state) => {
        state.vitamins_loading = true;
        state.vitamins_error = null;
      })
      .addCase(vitamins_updateData.fulfilled, (state, action) => {
        state.vitamins_loading = false;
        state.vitamins_error = false;
        state.vitamins_data = action.payload;
      })
      .addCase(vitamins_updateData.rejected, (state, action) => {
        state.vitamins_loading = false;
        state.vitamins_error = action.error.message;
      })
      .addCase(vitamins_uploadImg.pending, (state) => {
        state.vitamin_upload_loading = true;
        state.vitamin_upload_error = null;
      })
      .addCase(vitamins_uploadImg.fulfilled, (state, action) => {
        state.vitamin_upload_loading = false;
        state.vitamin_upload_error = false;
        state.vitamin_upload_data = action.payload;
      })
      .addCase(vitamins_uploadImg.rejected, (state, action) => {
        state.vitamin_upload_loading = false;
        state.vitamin_upload_error = action.error.message;
      });
  },
});

export default vitaminSlice.reducer;
