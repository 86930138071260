import { combineReducers, configureStore } from "@reduxjs/toolkit";

import authReducer from "./auth/authSlice";
import vitaminReducers from "./data/vitaminSlices/vitaminReducers";
import mineralReducers from "./data/mineralSlices/mineralReducers";
import ingredientCategoryReducers from "./data/ingredientCategorySlices/ingredientCategoryReducers";
import ingredientSubcategoryReducers from "./data/ingredientSubcategorySlices/ingredientSubcategoryReducers";
import ingredientReducers from "./data/ingredientSlices/ingredientReducers";
import recipeReducers from "./data/recipeSlices/recipeReducers";

const reducer = combineReducers({
  auth: authReducer,
  vitamins: vitaminReducers,
  minerals: mineralReducers,
  ingredient_categories: ingredientCategoryReducers,
  ingredient_subcategories: ingredientSubcategoryReducers,
  ingredients: ingredientReducers,
  recipes: recipeReducers,
});

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
