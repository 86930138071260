import { createSlice } from "@reduxjs/toolkit";
import {
  recipes_fetchAllData,
  // recipes_fetchDataBySubcategory,
  recipes_fetchDataByID,
  recipes_createData,
  recipes_uploadImg,
  recipes_updateData,
} from "./recipeActions";
const initialState = {
  all_recipes_data: null,
  all_recipes_loading: false,
  all_recipes_error: false,
  all_recipes_errCode: null,

  recipe_data: null,
  recipe_loading: false,
  recipe_error: false,
  recipe_errCode: null,

  recipe_upload_data: null,
  recipe_upload_loading: false,
  recipe_upload_error: false,
  recipe_upload_errCode: null,
};

const recipeSlice = createSlice({
  name: "recipes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(recipes_fetchAllData.pending, (state) => {
        state.all_recipes_loading = true;
        state.all_recipes_error = false;
      })
      .addCase(recipes_fetchAllData.fulfilled, (state, action) => {
        state.all_recipes_loading = false;
        state.all_recipes_data = action.payload;
      })
      .addCase(recipes_fetchAllData.rejected, (state, action) => {
        state.all_recipes_loading = false;
        state.all_recipes_error = true;
        state.all_recipes_errCode = action.error.message;
      })
      .addCase(recipes_fetchDataByID.pending, (state) => {
        state.recipe_loading = true;
        state.recipe_error = null;
      })
      .addCase(recipes_fetchDataByID.fulfilled, (state, action) => {
        state.recipe_loading = false;
        state.recipe_data = action.payload;
      })
      .addCase(recipes_fetchDataByID.rejected, (state, action) => {
        state.recipe_loading = false;
        state.recipe_error = action.error.message;
      })
      .addCase(recipes_createData.pending, (state) => {
        state.recipe_loading = true;
        state.recipe_error = null;
      })
      .addCase(recipes_createData.fulfilled, (state, action) => {
        state.recipe_loading = false;
        state.recipe_data = action.payload;
      })
      .addCase(recipes_createData.rejected, (state, action) => {
        state.recipe_loading = false;
        state.recipe_error = action.error.message;
      })
      .addCase(recipes_updateData.pending, (state) => {
        state.recipe_loading = true;
        state.recipe_error = null;
      })
      .addCase(recipes_updateData.fulfilled, (state, action) => {
        state.recipe_loading = false;
        state.recipe_error = false;
        state.recipe_data = action.payload;
      })
      .addCase(recipes_updateData.rejected, (state, action) => {
        state.recipe_loading = false;
        state.recipe_error = action.error.message;
      })
      .addCase(recipes_uploadImg.pending, (state) => {
        state.recipe_upload_loading = true;
        state.recipe_upload_error = null;
      })
      .addCase(recipes_uploadImg.fulfilled, (state, action) => {
        state.recipe_upload_loading = false;
        state.recipe_upload_error = false;
        state.recipe_upload_data = action.payload;
      })
      .addCase(recipes_uploadImg.rejected, (state, action) => {
        state.recipe_upload_loading = false;
        state.recipe_upload_error = action.error.message;
      });
  },
});

export default recipeSlice.reducer;
