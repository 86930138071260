import { createSlice } from "@reduxjs/toolkit";
import {
  ingredients_fetchAllData,
  ingredients_fetchDataBySubcategory,
  ingredients_fetchDataByID,
  ingredients_createData,
  ingredients_uploadImg,
  ingredients_updateData,
} from "./ingredientActions";
const initialState = {
  all_ingredients_data: null,
  all_ingredients_loading: false,
  all_ingredients_error: false,
  all_ingredients_errCode: null,

  ingredients_data: null,
  ingredients_loading: false,
  ingredients_error: false,
  ingredients_errCode: null,

  ingredient_upload_data: null,
  ingredient_upload_loading: false,
  ingredient_upload_error: false,
  ingredient_upload_errCode: null,
};

const ingredientSlice = createSlice({
  name: "ingredients",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ingredients_fetchAllData.pending, (state) => {
        state.all_ingredients_loading = true;
        state.all_ingredients_error = false;
      })
      .addCase(ingredients_fetchAllData.fulfilled, (state, action) => {
        state.all_ingredients_loading = false;
        state.all_ingredients_data = action.payload;
      })
      .addCase(ingredients_fetchAllData.rejected, (state, action) => {
        state.all_ingredients_loading = false;
        state.all_ingredients_error = true;
        state.all_ingredients_errCode = action.error.message;
      })
      .addCase(ingredients_fetchDataBySubcategory.pending, (state) => {
        state.ingredients_loading = true;
        state.ingredients_error = false;
      })
      .addCase(
        ingredients_fetchDataBySubcategory.fulfilled,
        (state, action) => {
          state.ingredients_loading = false;
          state.ingredients_data = action.payload;
        }
      )
      .addCase(ingredients_fetchDataBySubcategory.rejected, (state, action) => {
        state.ingredients_loading = false;
        state.ingredients_error = true;
        state.ingredients_errCode = action.error.message;
      })
      .addCase(ingredients_fetchDataByID.pending, (state) => {
        state.ingredients_loading = true;
        state.ingredients_error = null;
      })
      .addCase(ingredients_fetchDataByID.fulfilled, (state, action) => {
        state.ingredients_loading = false;
        state.ingredients_data = action.payload;
      })
      .addCase(ingredients_fetchDataByID.rejected, (state, action) => {
        state.ingredients_loading = false;
        state.ingredients_error = action.error.message;
      })
      .addCase(ingredients_createData.pending, (state) => {
        state.ingredients_loading = true;
        state.ingredients_error = null;
      })
      .addCase(ingredients_createData.fulfilled, (state, action) => {
        state.ingredients_loading = false;
        state.ingredients_data = action.payload;
      })
      .addCase(ingredients_createData.rejected, (state, action) => {
        state.ingredients_loading = false;
        state.ingredients_error = action.error.message;
      })
      .addCase(ingredients_updateData.pending, (state) => {
        state.ingredients_loading = true;
        state.ingredients_error = null;
      })
      .addCase(ingredients_updateData.fulfilled, (state, action) => {
        state.ingredients_loading = false;
        state.ingredients_error = false;
        state.ingredients_data = action.payload;
      })
      .addCase(ingredients_updateData.rejected, (state, action) => {
        state.ingredients_loading = false;
        state.ingredients_error = action.error.message;
      })
      .addCase(ingredients_uploadImg.pending, (state) => {
        state.ingredient_upload_loading = true;
        state.ingredient_upload_error = null;
      })
      .addCase(ingredients_uploadImg.fulfilled, (state, action) => {
        state.ingredient_upload_loading = false;
        state.ingredient_upload_error = false;
        state.ingredient_upload_data = action.payload;
      })
      .addCase(ingredients_uploadImg.rejected, (state, action) => {
        state.ingredient_upload_loading = false;
        state.ingredient_upload_error = action.error.message;
      });
  },
});

export default ingredientSlice.reducer;
